import React from 'react'
import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/auditoria.png'
import FooterContact from './../components/FooterContact'
import PageContentAdvisory from '../components/Solutions/Pages/Advisory'

const Auditoria = props => (
  <Layout
    title="Servicio de Auditoría"
    description="Nuestros servicios de auditoría poseen un enfoque integrado a las Normas Internacionales de Auditoría y, en combinación con nuestras políticas y procedimientos para la auditoría del control interno y de los estados financieros, forman una metodología única, para efectuar una auditoría eficaz basada en la efectividad del sistema de control interno."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentAdvisory />
    <FooterContact />
  </Layout>
)

export default Auditoria
