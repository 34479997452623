import React from 'react'
import _times from 'lodash/times'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'
import { ContractButtonSmall } from '../SolutionDetail.styled'

const PageContentAdvisory = () => {
  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.auditoria.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedHTMLMessage id="solutions.auditoria.content.p1" />
        </p>
        <h2>
          <FormattedMessage id="solutions.auditoria.content.subtitle" />
        </h2>
        <ul>
          {_times(13, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.auditoria.content.list.item${value}`}
              />
            </li>
          ))}
        </ul>
        <p>
          <FormattedHTMLMessage id="solutions.auditoria.content.p2" />
        </p>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>
  )
}

export default PageContentAdvisory
